// import { useEffect, useState } from 'react';
// import { useMutation, useQuery } from 'react-query';
// import { GraphQLClient, gql } from 'graphql-request';
// import { env } from 'configs/EnvironmentConfig';

// // GraphQL client setup
// const graphqlClient = new GraphQLClient(env.API_ENDPOINT_URL, {
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // GraphQL mutation for refreshing the token
// const REFRESH_TOKEN_MUTATION = gql`
//   mutation refreshToken {
//     refreshToken {
//       ... on AuthData {
//         token
//         tokenExpiration
//       }
//       ... on StatusResponse {
//         statusCode
//         message
//       }
//     }
//   }
// `;

// // Function to check if the token is expired
// const isTokenExpired = (token) => {
//   const { exp } = JSON.parse(atob(token.split(".")[1]));
//   const expiryDate = new Date(exp * 1000);
//   return new Date() > expiryDate;
// };

// // Function to refresh the token
// const refreshToken = async () => {
//   const token = localStorage.getItem("auth_token");

//   if (!token) {
//     console.log("No authorization token available. Logging out.");
//     localStorage.clear();
//     //window.location.href = "/auth/login";
//     throw new Error("No authorization token available");
//   }

//   try {
//     // Set the current token in the authorization header
//     graphqlClient.setHeader("Authorization", `Bearer ${token}`);

//     const refreshedData = await graphqlClient.request(REFRESH_TOKEN_MUTATION);
    
//     const statusCode = refreshedData.refreshToken.statusCode;
//     if (statusCode === 401) {
//       throw new Error("Authorization token is missing or invalid.");
//     } else if (statusCode === 403) {
//       throw new Error("Invalid token version, user not found, or invalid device ID.");
//     }

//     const newToken = refreshedData.refreshToken.token;
//     if (newToken) {
//       localStorage.setItem("auth_token", newToken);
//       console.log("Token successfully refreshed.");
//       return newToken;
//     } else {
//       throw new Error("Token refresh failed.");
//     }
//   } catch (error) {
//     console.error("Failed to refresh token:", error);
//     localStorage.clear();
//     //window.location.href = "/auth/login";
//     throw error;
//   }
// };

// // Function to handle requests with token management
// const requestWithTokenHandling = async (query, variables) => {
//   let token = localStorage.getItem("auth_token");

//   if (token && isTokenExpired(token)) {
//     console.log("Token is expired. Attempting to refresh...");
//     token = await refreshToken();
//   }

//   graphqlClient.setHeader("Authorization", `Bearer ${token}`);

//   try {
//     return await graphqlClient.request(query, variables);
//   } catch (error) {
//     const statusCode = error.response?.status;
//     if (statusCode === 400) {
//       console.log("Received 400: Invalid token. Redirecting to login.");
//       localStorage.clear();
//       //window.location.href = "/auth/login";
//     } else if (statusCode === 403) {
//       console.log("Received 403: Invalid token version, user not found, or invalid device ID.");
//       // Handle 403 specific logic here
//     } else {
//       throw error;
//     }
//   }
// };

// // Hook for GraphQL queries with token handling
// export const useGraphQLQuery = (key, query, variables = {}, options = {}) => {
//   return useQuery(
//     [key, variables],
//     () => requestWithTokenHandling(query, variables),
//     options
//   );
// };

// // Hook for GraphQL mutations with token handling
// export const useGraphQLMutation = (mutation, options = {}) => {

//   return useMutation(
//     (variables) => requestWithTokenHandling(mutation, variables),
//     options
//   );
// };

import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { GraphQLClient, gql } from 'graphql-request';
import { env } from 'configs/EnvironmentConfig';

// Function to check if the token is expired
const isTokenExpired = (token) => {
  const { exp } = JSON.parse(atob(token.split(".")[1]));
  const expiryDate = new Date(exp * 1000);
  return new Date() > expiryDate;
};

// Function to refresh the token
const refreshToken = async () => {
  const token = localStorage.getItem("auth_token");

  if (!token) {
    console.log("No authorization token available. Logging out.");
    localStorage.clear();
    throw new Error("No authorization token available");
  }

  try {
    // Create a new client instance for refreshing the token
    const graphqlClient = new GraphQLClient(env.API_ENDPOINT_URL, {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    });

    const REFRESH_TOKEN_MUTATION = gql`
      mutation refreshToken {
        refreshToken {
          ... on AuthData {
            token
            tokenExpiration
          }
          ... on StatusResponse {
            statusCode
            message
          }
        }
      }
    `;

    const refreshedData = await graphqlClient.request(REFRESH_TOKEN_MUTATION);

    const statusCode = refreshedData.refreshToken.statusCode;
    if (statusCode === 401) {
      throw new Error("Authorization token is missing or invalid.");
    } else if (statusCode === 403) {
      throw new Error("Invalid token version, user not found, or invalid device ID.");
    }

    const newToken = refreshedData.refreshToken.token;
    if (newToken) {
      localStorage.setItem("auth_token", newToken);
      console.log("Token successfully refreshed.");
      return newToken;
    } else {
      throw new Error("Token refresh failed.");
    }
  } catch (error) {
    console.error("Failed to refresh token:", error);
    localStorage.clear();
    throw error;
  }
};

// Function to check if any variable contains a File or Blob object
const containsFile = (variables) => {
  const checkForFile = (obj) => {
    if (obj instanceof File || obj instanceof Blob) {
      return true;
    }
    if (typeof obj === 'object' && obj !== null) {
      return Object.values(obj).some(value => checkForFile(value));
    }
    return false;
  };
  return checkForFile(variables);
};

// Function to handle requests with token management and multipart handling
const requestWithTokenHandling = async (query, variables) => {
  let token = localStorage.getItem("auth_token");

  if (token && isTokenExpired(token)) {
    console.log("Token is expired. Attempting to refresh...");
    token = await refreshToken();
  }

  const isFileUpload = containsFile(variables);

  if (isFileUpload) {
    // Construct a multipart form-data request
    const formData = new FormData();

    // Prepare operations object
    const operations = {
      query: typeof query === 'string' ? query : query.loc.source.body,
      variables: variables,
    };

    // Map files in variables
    const map = {};
    let fileIndex = 0;

    const files = {};

    const processVariables = (vars, path = 'variables') => {
      for (const key in vars) {
        const value = vars[key];
        if (value instanceof File || value instanceof Blob) {
          map[fileIndex] = [`${path}.${key}`];
          files[fileIndex] = value;
          vars[key] = null; // Set to null as per GraphQL multipart request spec
          fileIndex++;
        } else if (typeof value === 'object' && value !== null) {
          processVariables(value, `${path}.${key}`);
        }
      }
    };

    // Deep clone variables to avoid mutating the original object
    const clonedVariables = JSON.parse(JSON.stringify(variables));
    processVariables(clonedVariables);

    formData.append('operations', JSON.stringify(operations));
    formData.append('map', JSON.stringify(map));

    // Append files to formData
    for (const index in files) {
      formData.append(index, files[index]);
    }

    try {
      const response = await fetch(env.API_ENDPOINT_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      return result.data;
    } catch (error) {
      console.error("Error in requestWithTokenHandling (file upload):", error);
      throw error;
    }
  } else {
    // Use graphql-request client for non-file uploads
    const graphqlClient = new GraphQLClient(env.API_ENDPOINT_URL, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    try {
      return await graphqlClient.request(query, variables);
    } catch (error) {
      console.error("Error in requestWithTokenHandling:", error);
      throw error;
    }
  }
};

// Hook for GraphQL queries with token handling
export const useGraphQLQuery = (key, query, variables = {}, options = {}) => {
  return useQuery(
    [key, variables],
    () => requestWithTokenHandling(query, variables),
    options
  );
};

// Hook for GraphQL mutations with token handling
export const useGraphQLMutation = (mutation, options = {}) => {
  return useMutation(
    (variables) => requestWithTokenHandling(mutation, variables),
    options
  );
};

